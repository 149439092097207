import moment from "moment";

import {
  get_bookings,
  get_business_hours,
  upsert_business_hours,
  get_tables,
  upsert_tables,
  delete_table,
  get_zones,
  add_zone,
  update_zone,
  get_booking,
  get_booking_settings,
  upsert_booking_settings,
  get_guests_options,
  get_available_dates,
  get_available_table_start_times,
  create_booking,
  update_booking,
  update_bookings,
} from "@/requests";
import { BOOKING_STATUS } from "@/shared/const";

let autocomplete_timeout_id;

const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export default {
  namespaced: true,
  state: {
    bookings: [],
    selected_booking: null,
    selected_bookings: [],
    filters: {},
    tables: [],
    zones: [],
    settings: {},
    datatable_bookings: [],
    datatable_filters: {
      start_gte: moment().startOf("month").format("YYYY-MM-DD") + "T00:00:00",
      start_lt: moment().endOf("month").format("YYYY-MM-DD") + "T23:59:59",
      booking_type: "table",
      limit: 10,
      include_child_bookings: false,
      sorting_cursor: "start_at__asc__",
    },
    datatable_options: {},
    datatable_out_of_date: false,
    datatable_clear_selection_trigger: false,
    business_hours: [],
    allowed_dates: [],
    allowed_walk_in_dates: [],
    guest_options: [],
    walk_in_guest_options: [],
    available_start_times: {},
    available_walk_in_start_times: {},
    selected_booking_date: null,
    is_sending: false,
    is_fetching: false,
    is_fetching_options: false,
    is_datatable_fetching: false,
    next_page: null,
    prev_page: null,
    next_page_sorting: null,
    prev_page_sorting: null,
    total_results: 0,
    show_new_booking: false,
    show_update_snackbar: false,
    update_snackbar_text: "",
    unsaved_settings: false,
  },
  mutations: {
    SET_SELECTED_BOOKING(state, booking) {
      state.selected_booking = booking;
    },
    SET_SELECTED_BOOKINGS(state, bookings) {
      state.selected_bookings = bookings;
    },
    SET_BOOKINGS(state, bookings) {
      state.bookings = bookings;
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    ADD_FILTER(state, filter) {
      state.filters = { ...state.filters, ...filter };
    },
    REMOVE_FILTER(state, key) {
      if (Object.keys(state.filters).includes(key)) delete state.filters[key];
    },
    SET_TABLES(state, tables) {
      state.tables = tables;
    },
    SET_ZONES(state, zones) {
      state.zones = zones;
    },
    SET_SETTINGS(state, settings) {
      state.settings = settings;
    },
    SET_BUSINESS_HOURS(state, hours) {
      state.business_hours = hours;
    },
    SET_ALLOWED_DATES(state, allowed_dates) {
      state.allowed_dates = allowed_dates;
    },
    SET_ALLOWED_WALK_IN_DATES(state, allowed_dates) {
      state.allowed_walk_in_dates = allowed_dates;
    },
    SET_GUEST_OPTIONS(state, options) {
      state.guest_options = options;
    },
    SET_WALK_IN_GUEST_OPTIONS(state, options) {
      state.walk_in_guest_options = options;
    },
    SET_AVAILABLE_START_TIMES(state, times) {
      state.available_start_times = times;
    },
    SET_AVAILABLE_WALK_IN_START_TIMES(state, times) {
      state.available_walk_in_start_times = times;
    },
    SET_IS_SENDING(state, is_sending) {
      state.is_sending = is_sending;
    },
    SET_IS_FETCHING(state, is_fetching) {
      state.is_fetching = is_fetching;
    },
    SET_IS_DATATABLE_FETCHING(state, is_fetching) {
      state.is_datatable_fetching = is_fetching;
    },
    SET_IS_FETCHING_OPTIONS(state, is_fetching) {
      state.is_fetching_options = is_fetching;
    },
    SET_SELECTED_DATE(state, date) {
      state.selected_booking_date = date;
    },
    SET_CURSORS(state, cursors) {
      state.next_page = cursors.next_page;
      state.prev_page = cursors.prev_page;
      state.next_page_sorting = cursors.next_page_sorting;
      state.prev_page_sorting = cursors.prev_page_sorting;
    },
    SET_TOTAL_RESULTS(state, total) {
      state.total_results = total;
    },
    SET_DATATABLE_BOOKINGS(state, bookings) {
      state.datatable_bookings = bookings;
    },
    SET_DATATABLE_FILTERS(state, filters) {
      state.datatable_filters = filters;
    },
    ADD_DATATABLE_FILTER(state, filter) {
      state.datatable_filters = { ...state.datatable_filters, ...filter };
    },
    REMOVE_DATATABLE_FILTER(state, key) {
      if (Object.keys(state.datatable_filters).includes(key))
        delete state.datatable_filters[key];
    },
    SET_DATATABLE_OPTIONS(state, options) {
      state.datatable_options = options;
    },
    SET_DATATABLE_OUT_OF_DATE(state, out_of_date) {
      state.datatable_out_of_date = out_of_date;
    },
    CLEAR_DATATABLE_SELECTION(state) {
      state.datatable_clear_selection_trigger =
        !state.datatable_clear_selection_trigger;
    },
    TOGGLE_SHOW_NEW_BOOKING(state) {
      state.show_new_booking = !state.show_new_booking;
    },
    SET_SHOW_UPDATE_SNACKBAR(state, show) {
      state.show_update_snackbar = show;
    },
    SET_UPDATE_SNACKBAR_TEXT(state, text) {
      state.update_snackbar_text = text;
    },
    SET_UNSAVED_SETTINGS(state, boolean) {
      state.unsaved_settings = boolean;
    },
    CLEAR_STATE(state) {
      state.bookings = [];
      state.selected_booking = null;
      state.filters = {};
      state.tables = [];
      state.zones = [];
      state.settings = {};
      state.datatable_bookings = [];
      state.business_hours = [];
      state.allowed_dates = [];
      state.allowed_walk_in_dates = [];
      state.guest_options = [];
      state.walk_in_guest_options = [];
      state.available_start_times = {};
      state.available_walk_in_start_times = {};
    },
  },
  actions: {
    clear_state({ commit }) {
      commit("CLEAR_STATE");
    },
    async get_zones({ commit }, shop_id) {
      try {
        const { data: zones } = await get_zones(shop_id);
        commit("SET_ZONES", zones);
      } catch (error) {
        console.error(error);
      }
    },
    async get_settings({ commit }, shop_id) {
      try {
        const { data: settings } = await get_booking_settings(shop_id);
        commit("SET_SETTINGS", settings);
      } catch (error) {
        console.error(error);
      }
    },
    async upsert_booking_settings({ commit }, { shop_id, updated_settings }) {
      try {
        const { data: settings } = await upsert_booking_settings({
          shop_id,
          ...updated_settings,
        });
        commit("SET_SETTINGS", settings);
      } catch (error) {
        console.error(error);
      }
    },
    async get_booking(
      { commit, state },
      { booking_id, update_bookings = false }
    ) {
      try {
        const { data: booking } = await get_booking(booking_id);
        if (update_bookings) {
          commit("SET_BOOKINGS", [...state.bookings, booking]);
        } else {
          commit("SET_SELECTED_BOOKING", booking);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async get_bookings({ commit, state, rootState }) {
      commit("SET_IS_FETCHING", true);
      try {
        const {
          data: { items: bookings },
        } = await get_bookings({
          ...state.filters,
          shop_id: rootState.AdminStore.shop_id,
        });
        commit("SET_BOOKINGS", bookings);
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_IS_FETCHING", false);
      }
    },
    async get_datatable_bookings({ commit, state, rootState }) {
      commit("SET_IS_DATATABLE_FETCHING", true);
      try {
        const {
          data: {
            items: bookings,
            next_page: next_page,
            prev_page: prev_page,
            next_page_sorting: next_page_sorting,
            prev_page_sorting: prev_page_sorting,
            total_results: total_results,
          },
        } = await get_bookings({
          ...state.datatable_filters,
          shop_id: rootState.AdminStore.shop_id,
        });
        commit("SET_DATATABLE_BOOKINGS", bookings);
        commit("SET_CURSORS", {
          next_page,
          prev_page,
          next_page_sorting,
          prev_page_sorting,
        });
        commit("SET_TOTAL_RESULTS", total_results);
        commit("SET_DATATABLE_OUT_OF_DATE", false);
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_IS_DATATABLE_FETCHING", false);
      }
    },
    async get_tables({ commit }, shop_id) {
      try {
        const { data: tables } = await get_tables(shop_id);
        commit("SET_TABLES", tables);
      } catch (error) {
        console.error(error);
      }
    },
    async get_business_hours({ commit }, shop_id) {
      commit("SET_IS_FETCHING", true);
      try {
        const {
          data: { booking: hours },
        } = await get_business_hours(shop_id);
        commit("SET_BUSINESS_HOURS", hours);
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_IS_FETCHING", false);
      }
    },
    async upsert_business_hours({ commit }, business_hours) {
      try {
        const { data: hours } = await upsert_business_hours(business_hours);
        commit("SET_BUSINESS_HOURS", hours);
      } catch (error) {
        console.error(error);
      }
    },
    async upsert_tables({ commit }, tables_in) {
      try {
        const { data: tables } = await upsert_tables(tables_in);
        commit("SET_TABLES", tables);
      } catch (error) {
        console.error(error);
      }
    },
    async delete_table({ commit, state }, table) {
      let id = table?.id;
      const new_tables = state.tables.filter((t) => t !== table);
      if (id) {
        try {
          await delete_table(id);
        } catch (error) {
          console.error(error);
        }
      }
      commit("SET_TABLES", new_tables);
    },
    async update_zone({ commit, state }, updates) {
      try {
        const { data: zone } = await update_zone(updates);
        commit("SET_ZONES", [
          ...state.zones.filter((z) => z.id !== updates.id),
          zone,
        ]);
      } catch (error) {
        console.error(error);
      }
    },
    async add_zone({ commit, state, dispatch }, zone_in) {
      try {
        const { data: zone } = await add_zone(zone_in);
        commit("SET_ZONES", [...state.zones, zone]);
        dispatch("add_empty_table_to_zone", {
          zone_id: zone.id,
          name: null,
          priority: "4", // MEDIUM
          capacity: 2,
        });
      } catch (error) {
        console.error(error);
      }
    },
    async get_table_booking_data({ dispatch }, shop_id) {
      dispatch("get_settings", shop_id);
      dispatch("get_tables", shop_id);
      dispatch("get_zones", shop_id);
      dispatch("get_business_hours", shop_id);
    },
    async get_available_dates({ commit }, shop_id) {
      commit("SET_IS_FETCHING_OPTIONS", true);
      try {
        const { data: allowed_dates } = await get_available_dates(shop_id);
        commit("SET_ALLOWED_DATES", allowed_dates);
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_IS_FETCHING_OPTIONS", false);
      }
    },
    async get_available_walk_in_dates({ commit }, shop_id) {
      commit("SET_IS_FETCHING_OPTIONS", true);
      try {
        const { data: allowed_dates } = await get_available_dates(shop_id, {
          walk_in: true,
        });
        commit("SET_ALLOWED_WALK_IN_DATES", allowed_dates);
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_IS_FETCHING_OPTIONS", false);
      }
    },
    async get_guests_options({ commit }, { shop_id, params }) {
      commit("SET_IS_FETCHING_OPTIONS", true);
      try {
        const { data: options } = await get_guests_options(shop_id, params);
        commit("SET_GUEST_OPTIONS", options);
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_IS_FETCHING_OPTIONS", false);
      }
    },
    async get_walk_in_guests_options({ commit }, shop_id) {
      commit("SET_IS_FETCHING_OPTIONS", true);
      try {
        const { data: options } = await get_guests_options(shop_id, {
          walk_in: true,
          requested_date: moment().format("YYYY-MM-DD"),
        });
        commit("SET_WALK_IN_GUEST_OPTIONS", options);
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_IS_FETCHING_OPTIONS", false);
      }
    },
    async get_available_table_start_times({ commit }, { shop_id, params }) {
      commit("SET_IS_FETCHING_OPTIONS", true);
      try {
        const { data: times } = await get_available_table_start_times(
          shop_id,
          params
        );
        commit("SET_AVAILABLE_START_TIMES", times);
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_IS_FETCHING_OPTIONS", false);
      }
    },
    async get_available_walk_in_start_times({ commit }, { shop_id, params }) {
      commit("SET_IS_FETCHING_OPTIONS", true);
      try {
        const { data: times } = await get_available_table_start_times(shop_id, {
          ...params,
          walk_in: true,
          requested_date: moment().format("YYYY-MM-DD"),
        });
        commit("SET_AVAILABLE_WALK_IN_START_TIMES", times);
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_IS_FETCHING_OPTIONS", false);
      }
    },
    async create_booking({ commit }, params) {
      commit("SET_IS_SENDING", true);
      try {
        const { data: created_booking } = await create_booking(params);
        return created_booking;
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_IS_SENDING", false);
      }
    },
    async update_booking({ commit }, params) {
      commit("SET_IS_SENDING", true);
      try {
        const { data: updated_booking } = await update_booking(params);
        return updated_booking;
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_IS_SENDING", false);
      }
    },
    async update_bookings({ commit, state }, status) {
      commit("SET_IS_SENDING", true);
      try {
        const params = {
          ids: state.selected_bookings.map((b) => b.id),
          updates: {
            status,
          },
        };
        const { data: updated_bookings } = await update_bookings(params);
        return updated_bookings;
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_IS_SENDING", false);
      }
    },
    async process_booking_update({ commit, state }, update_data) {
      const affects_timeline = Object.keys(state.filters).includes("start_gte");
      const affects_table = Object.keys(state.datatable_filters).includes(
        "start_gte"
      );
      if (!affects_timeline && !affects_table) return;
      const booking_is_in_timeline = !!state.bookings.find(
        (b) => b.id == update_data.id
      );
      const booking_is_in_table = !!state.datatable_bookings.find(
        (b) => b.id == update_data.id
      );
      const start_at_moment = moment.utc(update_data.start_at, DATETIME_FORMAT);
      let new_start_at_in_timeline_range = false;

      if (affects_timeline) {
        const timeline_start_gte_moment = moment.utc(
          state.filters["start_gte"],
          DATETIME_FORMAT
        );
        const timeline_start_lt_moment = moment.utc(
          state.filters["start_lt"],
          DATETIME_FORMAT
        );
        new_start_at_in_timeline_range = start_at_moment.isBetween(
          timeline_start_gte_moment,
          timeline_start_lt_moment
        );
      }
      commit("SET_IS_FETCHING", true);
      let new_timeline_bookings = [...state.bookings];
      let new_table_bookings = [...state.datatable_bookings];
      try {
        const { data: booking } = await get_booking(update_data.id);
        if (affects_timeline) {
          if (new_start_at_in_timeline_range) {
            if (booking_is_in_timeline) {
              const booking_idx = new_timeline_bookings.indexOf(
                new_timeline_bookings.find((b) => b.id == booking.id)
              );
              new_timeline_bookings[booking_idx] = booking;
            } else {
              new_timeline_bookings = [...new_timeline_bookings, booking];
            }
          } else if (booking_is_in_timeline) {
            new_timeline_bookings.splice(
              new_timeline_bookings.indexOf(
                new_timeline_bookings.find((b) => b.id == booking.id)
              ),
              1
            );
          }
        }
        if (affects_table && booking_is_in_table) {
          const booking_idx = new_table_bookings.indexOf(
            new_table_bookings.find((b) => b.id == booking.id)
          );
          new_table_bookings[booking_idx] = booking;
        }
        if (state?.selected_booking?.id == booking.id) {
          commit("SET_SELECTED_BOOKING", booking);
        }
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_IS_FETCHING", false);
        if (affects_timeline) commit("SET_BOOKINGS", new_timeline_bookings);
        if (affects_table) {
          commit("SET_DATATABLE_BOOKINGS", new_table_bookings);
          commit("SET_DATATABLE_OUT_OF_DATE", true);
        }
      }
    },
    async display_queried_booking({ state, commit, dispatch }, booking_id) {
      commit("SET_IS_DATATABLE_FETCHING", true);
      try {
        const { data: booking } = await get_booking(booking_id);
        const booking_start_at_moment = moment(booking.start_at);
        const datatable_filters = {
          start_gte: booking_start_at_moment.format("YYYY-MM-DD") + "T00:00:00",
          start_lt: booking_start_at_moment.format("YYYY-MM-DD") + "T23:59:59",
          booking_type: "table",
          limit: 10,
          sorting_cursor: "start_at__asc__",
        };
        commit("SET_DATATABLE_FILTERS", datatable_filters);
        await dispatch("get_datatable_bookings");
        const queried_booking = state.datatable_bookings.find(
          (b) => b.id == booking_id
        );
        if (queried_booking) dispatch("select_booking", queried_booking);
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_IS_DATATABLE_FETCHING", false);
      }
    },
    select_booking({ commit, state }, booking) {
      if (booking && booking.booking_block_out_id) return;
      if (booking && booking.parent_booking_id) {
        booking = state.bookings.find((b) => b.id == booking.parent_booking_id);
      }
      commit("SET_SELECTED_BOOKING", booking);
    },
    select_bookings({ commit }, bookings) {
      commit("SET_SELECTED_BOOKINGS", bookings);
    },
    reset_datatable_with_sorting({ commit }, sorting_cursor) {
      // swaps the stored cursors preparing to reset to page 1
      // items are then fetched when the pagination event is emitted
      commit("SET_CURSORS", {
        next_page: null,
        prev_page: null,
        next_page_sorting: null,
        prev_page_sorting: sorting_cursor,
      });
    },
    set_datatable_options({ commit }, options) {
      commit("SET_DATATABLE_OPTIONS", options);
    },
    set_selected_date({ commit }, date) {
      commit("SET_SELECTED_DATE", date);
    },
    add_empty_table_to_zone({ state, commit }, new_table) {
      commit("SET_TABLES", [...state.tables, new_table]);
    },
    apply_filters({ commit }, filters) {
      commit("SET_FILTERS", filters);
    },
    apply_datatable_filters({ commit }, filters) {
      commit("CLEAR_DATATABLE_SELECTION");
      commit("SET_DATATABLE_FILTERS", filters);
    },
    add_filter({ commit }, filter) {
      commit("ADD_FILTER", filter);
    },
    remove_filter({ commit }, key) {
      commit("REMOVE_FILTER", key);
    },
    add_datatable_filter({ commit, dispatch }, filter) {
      commit("CLEAR_DATATABLE_SELECTION");
      let valid_dates;
      switch (Object.keys(filter)[0]) {
        case "autocomplete":
          dispatch("handle_datatable_autocomplete", filter);
          break;
        case "start_gte":
          valid_dates = dispatch("handle_datatable_start", filter);
          if (valid_dates) dispatch("determine_fetch_or_reset");
          break;
        case "start_lt":
          valid_dates = dispatch("handle_datatable_start", filter);
          if (valid_dates) dispatch("determine_fetch_or_reset");
          break;
        case "booking_status":
          dispatch("handle_datatable_status", filter);
          dispatch("determine_fetch_or_reset");
          break;
        default:
          commit("ADD_DATATABLE_FILTER", filter);
      }
    },
    remove_datatable_filter({ commit }, key) {
      commit("REMOVE_DATATABLE_FILTER", key);
    },
    handle_datatable_autocomplete({ state, commit, dispatch }, filter) {
      const search_term = filter["autocomplete"];
      if (
        search_term &&
        search_term.length > 1 &&
        search_term != state.datatable_filters["autocomplete"]
      ) {
        commit("ADD_DATATABLE_FILTER", filter);
      } else if (search_term == null || search_term?.length < 1) {
        commit("REMOVE_DATATABLE_FILTER", "autocomplete");
      }

      clearTimeout(autocomplete_timeout_id);
      autocomplete_timeout_id = setTimeout(() => {
        dispatch("determine_fetch_or_reset");
      }, 500);
    },
    handle_datatable_start({ state, commit }, filter) {
      commit("ADD_DATATABLE_FILTER", filter);
      const from_moment = moment(state.datatable_filters.start_gte);
      const to_moment = moment(state.datatable_filters.start_lt);
      if (to_moment.isBefore(from_moment)) return false;
    },
    handle_datatable_status({ state, commit }, filter) {
      const statuses = filter["booking_status"];
      if (
        statuses.length &&
        state.datatable_filters["booking_status"] != statuses
      ) {
        filter["booking_status"] = filter["booking_status"].join(",");
        commit("ADD_DATATABLE_FILTER", filter);
      } else if (!statuses.length) {
        commit("REMOVE_DATATABLE_FILTER", "booking_status");
      }
    },
    determine_fetch_or_reset({ state, dispatch, commit }) {
      commit("REMOVE_DATATABLE_FILTER", "cursor");
      const sorting_vars =
        state.datatable_filters["sorting_cursor"].split("__");
      const sorting_cursor = [sorting_vars[0], sorting_vars[1], ""].join("__");
      commit("ADD_DATATABLE_FILTER", { sorting_cursor });
      dispatch("reset_datatable_with_sorting", sorting_cursor);
      if (state.datatable_options["page"] === 1) {
        dispatch("get_datatable_bookings");
      } else {
        const default_datatable_options = {
          itemsLength: 0,
          itemsPerPage: state.datatable_filters["limit"],
          page: 1,
          pageCount: 0,
          pageStart: 0,
          pageStop: 0,
        };
        commit("SET_DATATABLE_OPTIONS", default_datatable_options);
      }
    },
    toggle_show_new_booking({ commit }) {
      commit("TOGGLE_SHOW_NEW_BOOKING");
    },
    set_show_update_snackbar({ commit }, show) {
      commit("SET_SHOW_UPDATE_SNACKBAR", show);
    },
    display_update_snackbar({ commit }, text) {
      commit("SET_UPDATE_SNACKBAR_TEXT", text);
      commit("SET_SHOW_UPDATE_SNACKBAR", true);
    },
    set_datatable_out_of_date({ commit }, out_of_date) {
      commit("SET_DATATABLE_OUT_OF_DATE", out_of_date);
    },
    set_unsaved_settings({ commit }, boolean) {
      commit("SET_UNSAVED_SETTINGS", boolean);
    },
  },
  getters: {
    is_table_booking_enabled: (state, getters, rootState, rootGetters) => {
      return rootGetters?.["ShopStore/table_booking"]?.is_enabled ?? false;
    },
    is_available_for_walk_in: (state) => {
      return state.allowed_walk_in_dates.includes(
        moment().format("YYYY-MM-DD")
      );
    },
    bookings_chronologically: (state) => {
      const sorted_bookings = [...state.bookings].filter(
        (b) => b.status != "cancelled"
      );
      sorted_bookings.sort((a, b) => {
        return new Date(a.start_at) - new Date(b.start_at);
      });
      return sorted_bookings;
    },
    selected_bookings_to_update: (state) => {
      return state.selected_bookings.filter(
        (b) => b.status != BOOKING_STATUS.CANCELLED
      );
    },
    tables_by_zone: (state) => {
      let tables_by_zone = {};
      for (let zone of state.zones) {
        tables_by_zone[zone.id] = [];
      }
      for (let table of state.tables) {
        tables_by_zone[table.zone_id].push(table);
      }
      return tables_by_zone;
    },
    intervals_per_hour: (state) => {
      return 60 / state.settings.interval || 4;
    },
    timeline_node_width: (state) => {
      return (state.settings.interval / 15) * 28;
    },
    pixels_per_minute: (state, getters) => {
      return getters.timeline_node_width / state.settings.interval;
    },
    pixels_per_hour: (state, getters) => {
      return getters.timeline_node_width * getters.intervals_per_hour;
    },
    booking_settings_state(state, getters, rootState) {
      return {
        duration: state.settings?.duration !== undefined,
        min_notice: state.settings?.min_notice !== undefined,
        max_notice: state.settings?.max_notice !== undefined,
        interval: state.settings?.interval !== undefined,
        empty_seats: state.settings?.empty_seats !== undefined,
        turnaround_time: state.settings?.turnaround_time !== undefined,
        hospitality_pricing_guide:
          rootState.AdminStore.shop?.hospitality_pricing_guide !== null ||
          false,
      };
    },
    table_booking_state(state, getters) {
      let business_hours_ready;

      if (state.business_hours) {
        if (Array.isArray(state.business_hours)) {
          business_hours_ready = !!state.business_hours.length;
        } else {
          business_hours_ready = Object.values(state.business_hours).some(
            (day) => day.length > 0
          );
        }
      } else {
        business_hours_ready = false;
      }

      return {
        is_general_ready: Object.values(getters.booking_settings_state).every(
          Boolean
        ),
        is_hours_ready: business_hours_ready,
        is_tables_ready: !!state.tables.filter((hours) => hours.name).length,
        is_zones_ready: !!state.zones.length,
      };
    },
  },
};
